import React, { useState, useEffect } from "react";
import DataTable from "react-data-table-component";
import { DebounceInput } from "react-debounce-input";
import { StyledContainer, StyledOrderBlock, StyledPageTitle, SearchField } from "./style";
import { toast } from "react-toastify";
import { getProcessedByDetails } from "../../../features/order/orderSlice";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

const ProcessedByOrderList = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(10);
    const [processedOrderData, setprocessedOrderData] = useState([]);
    const isLoading = useSelector((state) => state?.order?.loading);
    const [searchText, setSearchText] = useState("");
    const [dateField, setDateField] = useState({
        fromDate: "",
        toDate: ""
    });
    const processedOrderDetails = useSelector(state => state?.order?.processedOrderDetails);


    useEffect(() => {
        setprocessedOrderData(processedOrderDetails);
    }, [processedOrderDetails]);


    console.log(processedOrderDetails);
    useEffect(() => {
        dispatch(getProcessedByDetails({})).unwrap().then((res) => {
            if (res.status === 1) {
                toast.success(`${res?.message}`, {
                    className: "toast-message",
                });
            } else {
                toast.error(`${res?.message}`, {
                    className: "toast-message",
                });
            }
        });

        document.title = "Malaysia-Arrival Card";
    }, [page, limit]);
    const handleSearch = (e) => {
        setSearchText(e.target.value);
        const filterText = e.target.value;
        if (
            processedOrderDetails &&
            processedOrderDetails.length > 0 &&
            processedOrderData
        ) {
            if (filterText !== "") {
                console.log("filterText ", filterText);
                console.log(typeof filterText);
                const filteredResult = processedOrderDetails
                    ?.filter((item) =>
                        (item?.processed_by)
                            .toLowerCase()
                            .includes(filterText.toLocaleLowerCase())
                    )
                    .map((item) => {
                        return {
                            processed_by: item?.processed_by,
                            count: item?.count
                        };
                    });
                setprocessedOrderData(filteredResult);
            } else {
                const stateData = processedOrderDetails?.map((item) => {
                    return {
                        processed_by: item?.processed_by ? item?.processed_by : '-',
                        count: item?.count ? item?.count : '-',
                    };
                });
                setprocessedOrderData(stateData);
            }
        }
    };


    const handleSubmit = () => {
        const data = { fromDate: dateField.fromDate, toDate: dateField.toDate }
        dispatch(getProcessedByDetails(data)).unwrap().then((res) => {
            if (res.status === 401) {
                toast.error(`${res?.message}`, { className: "toast-message" });
                localStorage.removeItem("user");
                navigate("/");
            }
        });
    }

    const columns = [
        {
            name: "S. No",
            selector: (row, index) => (index + 1),
            sortable: true,
        },
        {
            name: "Name",
            selector: (row) => row.processed_by,
            sortable: true,
        },
        {
            name: "Order Count",
            selector: (row) => row.count,
            sortable: true,
        }
        // {
        //     name: "Standard Processing",
        //     selector: (row) => row.standard_count,
        //     sortable: true,
        // },
        // {
        //     name: "Priority Processing",
        //     selector: (row) => row.priority_count,
        //     sortable: true,
        // },
        // {
        //     name: "Future Order Processing",
        //     selector: (row) => row.future_count,
        //     sortable: true,
        // },
    ];

    return (
        <StyledContainer>
            <StyledPageTitle>
                <h1>Processed By </h1>
            </StyledPageTitle>
            <form>
                <div className="d-flex">
                    <div className="form-group">
                        <label>
                            From:
                            <input
                                type="date"
                                name="fromDate"
                                id="fromDate"
                                className="form-control input-class"
                                value={dateField.fromDate}
                                onChange={(e) => setDateField({ ...dateField, fromDate: e.target.value })}
                            />
                        </label>
                    </div>
                    <div className="form-group">
                        <label>
                            To:
                            <input
                                type="date"
                                name="toDate"
                                id="toDate"
                                className="form-control input-class"
                                value={dateField.toDate}
                                onChange={(e) => setDateField({ ...dateField, toDate: e.target.value })}
                            />
                        </label>
                    </div>
                    <div className="form-group">
                        <button
                            type="button"
                            className="blue-btn"
                            id="download_btn"
                            onClick={(e) => {
                                handleSubmit(e);
                            }}
                        >
                            Submit
                        </button>
                    </div>
                </div>
                <StyledOrderBlock>
                    <SearchField>
                        <DebounceInput
                            minLength={0}
                            debounceTimeout={500}
                            type="text"
                            id="search_field"
                            name="search_field"
                            className="search-bar form-control"
                            value={searchText}
                            onChange={(e) => handleSearch(e)}
                            placeholder="Search"
                        />
                    </SearchField>
                    <div className="table-block table-responsive">
                        <DataTable
                            className="uspassport-custom-tbl"
                            columns={columns}
                            progressPending={isLoading}
                            data={processedOrderData}
                            fixedHeader={true}
                            fixedHeaderScrollHeight="550px"
                            pagination={processedOrderData?.length ? true : false}
                            noDataComponent="No records found"
                            setPage={setPage}
                            setLimit={setLimit}
                            selectableRows={false}
                        />
                    </div>
                </StyledOrderBlock>
            </form>
        </StyledContainer>
    );
}

export default ProcessedByOrderList;