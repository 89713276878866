import styled from "styled-components";
import backgrounImg from "../../../assets/images/drop-down.png";

export const StyledContainer = styled.div``;

export const StyledPageTitle = styled.div`
  background: grey;
  color: #fff;
  border-radius: 5px;
  padding: 10px 25px;
  margin-bottom: 20px;
  display: flex;
  gap: 8px;
  align-items: baseline;

  & h1 {
    font-size: 20px;
    color: #ffffff;
    font-weight: 600;
  }
`;

export const StyledOrderBlock = styled.div`
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.13);
  border-radius: 5px;
  padding: 15px 25px;
  margin-bottom: 20px;

  & h3 {
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 15px;
  }

  .table-block table {
    width: 100%;
  }

  .table-block table th {
    font-weight: 600;
    font-size: 18px;
    padding: 10px;
  }
  .table-block table td {
    border-top: 1px solid #e8f5ff;
    font-size: 16px;
    padding: 10px;
    vertical-align: top;
  }
  .table-block table tr:nth-child(even) td {
    background: #f9fcff;
  }

  .select-box {
    border: 1px solid #e1e1e1;
    height: 40px;
    box-shadow: none;
    max-width: 260px;
    background-image: url(${backgrounImg});
    background-repeat: no-repeat;
    background-position: 93%;
  }
  & div:first-child {
    white-space: pre-line;
    text-overflow: ellipsis;
    // min-width: fit-content;
  }
  .dMpKVF {
    display: flex;
    -webkit-box-align: stretch;
    align-items: stretch;
    align-content: stretch;
    width: 100%;
    box-sizing: border-box;
    font-size: 13px;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.87);
    background-color: rgb(255, 255, 255);
    min-height: 80px !important;
  }
  .form-control {
    display: block;
    width: 100%;
    height: 40px;
    padding: 6px 12px;
    font-size: 14px;
    line-height: 1.42857143;
    color: #555;
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
`;

export const StyledBottomBlock = styled.div`
  margin-top: 35px;
  .blue-btn {
    margin: 0px 10px 10px 0px;
  }
  .blue-btn {
    background-color: #ce1127;
    color: #ffffff;
    font-size: 16px;
    font-weight: 600;
    padding: 15px 30px;
    display: inline-block;
  }
  .blue-btn img {
    margin-left: 10px;
    vertical-align: middle;
    display: inline-block;
  }
  .blue-btn:hover,
  .blue-btn:focus {
    background-color: #0b3157;
    color: #fff;
    text-decoration: none;
  }
`;
